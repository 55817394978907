import { ThemeManager } from '.';

export class ScopedThemeManager {
  private themeManager: ThemeManager;
  private isExternal: boolean;
  private mfeCSS = new Set<string>();
  constructor(themeManager: ThemeManager, isExternal: boolean) {
    this.themeManager = themeManager;
    this.isExternal = isExternal;
  }

  async addCSS(url: string | string[], global = false) {
    if (this.isExternal && global) {
      console.warn(
        'Adding global styles is not allowed for 3rd party microfrontends',
      );
      return;
    }
    if (!global) {
      (url instanceof Array ? url : [url]).forEach((u) => this.mfeCSS.add(u));
    }
    return this.themeManager.addCSS(url, global);
  }

  getStyles(
    urls: string[],
    includeGlobals = true,
    includeMFECss = true,
  ): CSSStyleSheet[] {
    const stylesUrls = includeMFECss ? Array.from(this.mfeCSS) : [];
    return this.themeManager.getStyles(
      [...stylesUrls, ...urls],
      includeGlobals,
    );
  }
}
