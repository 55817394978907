import { createQueryString, populateUrl } from './link.helpers';
import {
  ApplicationLinkConfiguration,
  Link,
  LinkNavigateParams,
  LinkQueryParams,
} from './types';

export class ApplicationLink implements Link {
  public readonly path?: string;
  public readonly basePath: string;
  public readonly id: string;
  public readonly appId: string;
  constructor(linkConfiguration: ApplicationLinkConfiguration) {
    this.path = linkConfiguration.path?.replace(/^\//, '');
    this.basePath = linkConfiguration.basePath.replace(/\/$/, '');
    this.id = linkConfiguration.id || linkConfiguration.appId;
    this.appId = linkConfiguration.appId;
  }

  navigate(params?: LinkNavigateParams, queryParams?: LinkQueryParams): void {
    window.history.pushState(null, '', this.getUrl(params, queryParams));
  }
  getUrl(params?: LinkNavigateParams, queryParams?: LinkQueryParams): string {
    // not really compatible with server side rendering
    return new URL(this.prepare(params, queryParams), window.location.href)
      .href;
  }
  prepare(params?: LinkNavigateParams, queryParams?: LinkQueryParams): string {
    const parts = [this.basePath, this.path].filter((part) => !!part);
    const queryString = createQueryString(queryParams);
    return (
      populateUrl(parts.join('/'), params) + (queryString && `?${queryString}`)
    );
  }
  serialize() {
    return {
      path: this.path,
      basePath: this.basePath,
      id: this.id,
      appId: this.appId,
    };
  }
}

export const composeLink = (
  link: ApplicationLink,
  composition: { path: string },
) => {
  const configuration = link.serialize();
  if (configuration.path) {
    throw new Error('Link already has a path');
  }
  return new ApplicationLink({
    ...configuration,
    ...composition,
  });
};
