import { LinkQueryParams, PlainFlatObject } from '../types';

export const populateUrl = (
  path: string = '',
  params?: PlainFlatObject,
): string => {
  return path
    .split('/')
    .map((part) => {
      if (part.startsWith(':')) {
        const param = String(params && params[part.slice(1)]);
        return encodeURIComponent(param) || 'undefined';
      }
      return part;
    })
    .join('/');
};

export const createQueryString = (queryParams?: LinkQueryParams): string => {
  if (!queryParams) {
    return '';
  }
  const url = new URL('http://example.com');
  Object.entries(queryParams).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      url.searchParams.append(key, `${value}`);
    }
  });
  return url.search.slice(1); // Remove the leading "?"
};
