import { createQueryString, populateUrl } from './link.helpers';
import {
  ExternalLinkConfiguration,
  Link,
  LinkNavigateParams,
  LinkQueryParams,
} from './types';

export class ExternalLink implements Link {
  id: string;
  url: string;
  constructor(linkConfiguration: ExternalLinkConfiguration) {
    this.url = linkConfiguration.url;
    this.id = linkConfiguration.id;
  }

  navigate(params?: LinkNavigateParams, queryParams?: LinkQueryParams): void {
    window.location.assign(this.getUrl(params, queryParams));
  }
  getUrl(params?: LinkNavigateParams, queryParams?: LinkQueryParams): string {
    return new URL(this.prepare(params, queryParams)).href;
  }
  prepare(params?: LinkNavigateParams, queryParams?: LinkQueryParams): string {
    const queryString = createQueryString(queryParams);
    return populateUrl(this.url, params) + (queryString && `?${queryString}`);
  }
  serialize() {
    return {
      url: this.url,
      id: this.id,
    };
  }
}
