import { Link, LinkNavigateParams, LinkQueryParams } from '../links-manager';
import { BreadcrumbNode } from './types';

export interface BreadcrumbConfiguration {
  path?: string;
  url?: string;
  params?: LinkNavigateParams;
  queryParams?: LinkQueryParams;
  text: string;
  icon?: string;
}

export class Breadcrumb implements BreadcrumbNode {
  public text: string;
  private params?: LinkNavigateParams;
  private queryParams?: LinkQueryParams;
  public icon?: string;
  constructor(
    config: BreadcrumbConfiguration,
    public link: Link,
  ) {
    this.text = config.text;
    this.params = config.params;
    this.queryParams = config.queryParams;
    this.icon = config.icon;
  }
  navigate(params?: LinkNavigateParams, queryParams?: LinkQueryParams) {
    this.link.navigate(params || this.params, queryParams || this.queryParams);
  }
}
