import { NoneAuthConfiguration } from '../../configuration/schemas/auth';
import { UserInfo } from '../../types';
import { AuthService } from '../auth.service';

export class NoAuth implements AuthService {
  constructor(public accessToken: string = 'No token') {}

  userInfo: UserInfo = {
    name: 'noname',
    email: 'mail@example.com',
  };
  static async create(
    configuration: NoneAuthConfiguration,
  ): Promise<AuthService> {
    return new NoAuth(configuration.token);
  }
  async logout(): Promise<void> {
    console.warn('logged out');
  }
}
